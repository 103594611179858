import * as React from 'react';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { BUILDING_MANAGER } from '../../constants';
import { toast } from 'react-toastify';
import { activate, deactivate } from '../../api/buildings'
import { confirmAlert } from 'react-confirm-alert';
import ConfirmDialog from '../../components/Shared/ConfirmDialog';

export default function({ building }) {
  const [isActive, setActive] = React.useState(building.active);
  const hasRole = building.Roles.includes(BUILDING_MANAGER);
  const { t } = useTranslation();
  const label = isActive ? 'Deactivate' : 'Activate';

  const activeDesactive = async () => {
    const changeText = isActive ? 'deactivate' : 'activate';
    const complementaryText = isActive ? 'Building will be unacessible' : '';
    const onConfirmClick = async () => {
      try{
        if (isActive){
          const resp = await deactivate(building.id);
          if (!resp)
            throw new Error('NOT RESP');
        }
        else{
          const resp = await activate(building.id);
          if (!resp)
            throw new Error('NOT RESP');
        }
        setActive(!isActive);
        toast.success(t('The building has been updated'));
      }
      catch (error){
        toast.error(error.message || error.error);
      }
    }

    confirmAlert({
      message: t(
        `Are you sure you want to ${changeText} the building? ${complementaryText}` 
      ),
      customUI: (props) => <ConfirmDialog { ...props } {...{ onConfirmClick }}/>
    });
  }

  if (!hasRole) {
    return null;
  }

  return (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      fullWidth
      style={{ marginTop: '5px' }}
      onClick={activeDesactive}
    >
      {t(label)}
    </Button>
  );
}